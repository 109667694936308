import Immutable from "immutable";
import { getStorageValue, removeStorageValue, setStorageValue } from "./storage";
import { CLEAR_APP_CONFIG, CLEAR_CONFIG, CLEAR_COMMON_CONFIG, RESET_APP_CONFIG, RESET_COMMON_CONFIG, RESET_CONFIG, RESET_COUNTRY_CONFIG, RESET_COUNTRY_BOUNDARY_PRIMARY, RESET_COUNTRY_PRODUCTION_ALL, RESET_COUNTRY_PRODUCTION_FARM_STATS_CAMPAIGN_FLOW, RESET_COUNTRY_PRODUCTION_FARM_STATS_FARM_PRICE_VARIATION, RESET_COUNTRY_PRODUCTION_FARM_STATS_FARM_LAND, RESET_RESOURCE_CONFIG, SET_CONFIG, SET_MULTIPLE_CONFIG, USD } from "./constants";
const prefix = "config.";
const defaultState = {
  admLevel: 0,
  admLocationId: null,
  country: getStorageValue("country", null, prefix),
  genderId: null,
  locale: getStorageValue("locale", null, prefix),
  type: null,
  currencyCode: null
};
const initialState = Immutable.Map(defaultState);
export const setConfig = (config, value) => dispatch => {
  if (config) {
    dispatch({
      type: SET_CONFIG,
      config,
      value
    });
  }
};
export const setMultipleConfig = config => dispatch => {
  if (config) {
    dispatch({
      type: SET_MULTIPLE_CONFIG,
      config
    });
  }
};
export const clearConfig = () => dispatch => {
  dispatch({
    type: CLEAR_CONFIG
  });
};
export const resetClusterConfig = () => dispatch => {
  const config = {
    admLevel: 0,
    admLocationId: null,
    excludes: {
      farmAssociationGroups: [],
      farmAssociations: [],
      farmGroups: [],
      farms: [],
      individualFarmGroups: [],
      individualFarms: []
    },
    genderId: null,
    includes: {
      farmAssociationGroups: [],
      farmAssociations: [],
      farmGroups: [],
      farms: [],
      individualFarmGroups: [],
      individualFarms: []
    }
  };
  dispatch({
    type: SET_MULTIPLE_CONFIG,
    config
  });
};
export const clearAllConfigs = () => dispatch => {
  dispatch({
    type: CLEAR_COMMON_CONFIG
  });
  dispatch({
    type: CLEAR_APP_CONFIG
  });
  dispatch({
    type: CLEAR_CONFIG
  });
};
export const switchLocale = (locale, country = null, level = 1) => dispatch => {
  if (country) {
    dispatch({
      type: RESET_COUNTRY_CONFIG,
      data: {
        country
      }
    });
    dispatch({
      type: RESET_COUNTRY_BOUNDARY_PRIMARY,
      data: {
        country,
        level
      }
    });
    dispatch({
      type: RESET_COUNTRY_PRODUCTION_ALL,
      data: {
        country
      }
    });
    dispatch({
      type: RESET_COUNTRY_PRODUCTION_FARM_STATS_CAMPAIGN_FLOW,
      data: {
        country
      }
    });
    dispatch({
      type: RESET_COUNTRY_PRODUCTION_FARM_STATS_FARM_PRICE_VARIATION,
      data: {
        country
      }
    });
    dispatch({
      type: RESET_COUNTRY_PRODUCTION_FARM_STATS_FARM_LAND,
      data: {
        country
      }
    });
    dispatch({
      type: RESET_RESOURCE_CONFIG,
      data: {
        country
      }
    });
  }
  dispatch({
    type: RESET_COMMON_CONFIG
  });
  dispatch({
    type: RESET_APP_CONFIG
  });
  dispatch({
    type: RESET_CONFIG
  });
  dispatch({
    type: SET_CONFIG,
    config: "locale",
    value: locale
  });
};
export const isNotCached = (lastFetch, cachePeriod) => {
  if (!lastFetch || lastFetch && (new Date().getTime() - new Date(lastFetch).getTime()) / 1000 > cachePeriod) {
    return true;
  }
  return false;
};
export const switchCurrency = currencyCode => dispatch => {
  dispatch({
    type: SET_CONFIG,
    config: "currencyCode",
    value: currencyCode
  });
};
export default ((state = initialState, action) => {
  switch (action.type) {
    case SET_CONFIG:
      {
        const {
          config,
          value
        } = action;
        setStorageValue(config, value, prefix);
        return Array.isArray(config) ? state.setIn(config, value) : state.set(config, value);
      }
    case SET_MULTIPLE_CONFIG:
      {
        const {
          config
        } = action;
        Object.keys(config).forEach(key => {
          setStorageValue(key, config[key], prefix);
        });
        return Immutable.Map({
          ...state.toJS(),
          ...config
        });
      }
    case CLEAR_CONFIG:
      {
        removeStorageValue("country", prefix);
        removeStorageValue("locale", prefix);
        return state.set("country", null).set("locale", null);
      }
    default:
      return state;
  }
});